/**
 * Animation class
 * Mettre ici les trucs généraux qui concernent les animations.
 *
 * @author Stef Funaro
 *
 */
import { App } from "../App";
import { ThrottleHelper } from "../helpers/ThrottleHelper";

import AOS from "aos";
import Rellax from "rellax";
import { GradientsColors } from "../animations/GradientsColors";
import { GradientsBgBtn } from "../animations/GradientsBgBtn";
import { BtnScrollColor } from "../animations/BtnScrollColor";

export class Animation {
  constructor(protected app: App) {
    this._doCustomAnimations();
  }

  init(): Animation {
    this._doCustomAnimations();
    this._setRellax();
    this._setAOS();
    this._setGranim();
    // this._setVivus();
    // this._setMarquee();

    // On Scroll
    // document.addEventListener("scroll", ThrottleHelper.throttle(this._handleScroll, 100, this));

    return this;
  }

  /***************************************************
   * Privates
   **************************************************/

  /**
   * Custom animations
   */
  _doCustomAnimations(): void {
    // gsap.to(".module-hero", 2, {opacity:1});
  }

  /**
   * _handleScroll animations
   * @private
   */
  _handleScroll(): void { }

  _setGranim(): void {
    document.querySelectorAll(".granim-photo").forEach((granimEl: HTMLCanvasElement, index) => {
      var gradientAnim = new GradientsColors(granimEl, { "image": granimEl.dataset.src, "fullwidth": granimEl.classList.contains("full-width"), "scrollLess": granimEl.classList.contains("scroll-less") });
    });
    document.querySelectorAll(".granim-btn").forEach((granimEl: HTMLAnchorElement, index) => {
      var gradientAnim = new GradientsBgBtn(granimEl);
    });
    document.querySelectorAll(".scrollcolor-btn").forEach((scrollColorEl: HTMLAnchorElement, index) => {
      var scrollColorAnim = new BtnScrollColor(scrollColorEl);
    });
    return;
  }

  /**
   * Set Rellax
   * Animation parallax en scroll (éléments flottants)
   * https://dixonandmoe.com/rellax/
   *
   * Attention:
   * Faire npm install rellax --save
   * Après: import dans ce fichier
   *
   */

  _setRellax(): void {
    const rellax = new Rellax(".rellax", {
      speed: -2,
      center: true,
      wrapper: document.getElementById("main-scroll") ? "#main-scroll" : undefined,
      //wrapper: ".section-point-a-z",
      // round: true,
      // vertical: true,
      // horizontal: false
    });
  }

  /**
   * _setVivus
   * @private
   *
   * Animation de svg. Effect cool de svg qui se dessine, ne fonctionne que sur les "strokes"
   * ATTENTION: Il faut installer la librairie: npm install vivus
   * Et l'importer dans ce fichier
   *
   * https://maxwellito.github.io/vivus/
   * Preview svg: https://maxwellito.github.io/vivus-instant/
   *
   */
  _setVivus(): void {
    // Loop et ajout automatique d'ID pour animer les svg
    // let vivCnt = 0;
    // $(".animated-svg").each(function () {
    // 	$(this).attr("id", "viv-" + vivCnt);
    // 	new Vivus("viv-" + vivCnt, { duration: 120, delay: 100 });
    // 	vivCnt++;
    // });
  }

  /**
   * _setMarquee
   * @private
   *
   * Bande défilante (vanilla)
   * https://www.npmjs.com/package/vanilla-marquee
   *
   * ATTENTION: installer avec npm i vanilla-marquee
   * Et importer: import marquee from 'vanilla-marquee'
   *
   */
  _setMarquee(): void {
    // new marquee( element, {
    // 	options
    // })
  }

  /**
   * _setAOS
   * @private
   *
   * Animation en scroll
   *
   */
  _setAOS(): void {
    // AOS
    AOS.init({
      // Global settings:
      // disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      // startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      // initClassName: 'aos-init', // class applied after initialization
      // animatedClassName: 'aos-animate', // class applied on animation
      // useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      // disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      // debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      // throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

      // // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      // offset: 120, // offset (in px) from the original trigger point
      // delay: 0, // values from 0 to 3000, with step 50ms
      duration: 1800, // values from 0 to 3000, with step 50ms
      // easing: 'ease', // default easing for AOS animations
      once: true, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      // anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    });

    document.getElementById("main-scroll")?.addEventListener("scroll", () => {
      AOS.refresh();
    });
  }
}
